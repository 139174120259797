import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

const arrowDown = document.querySelectorAll('[data-section]')

arrowDown.forEach((arrow) => {
  const section = document.querySelector(arrow.dataset.section)
  arrow.addEventListener('click', (event) => {
    event.preventDefault()
    section.scrollIntoView({ block: 'start', behavior: 'smooth' })
  })
})
