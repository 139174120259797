import { openPopup } from '@modules/popup'

import { initData, setInitAuth } from '../init-state'
import { isMobile } from '../util'

import { authLogin } from './auth'
import { getMatches, getMatchesForAuth } from './matches'
import { initPredictEvent } from './predict'
import { getSettings } from './settings'

const promoUnauthorizedPopup = document.querySelector('#promo-unauthorized')

initData.isMobile = isMobile()

export const initMatches = () => {
  setInitAuth()
  authLogin().then(() => {
    if (!initData.isVoteNotAvailableAll) {
      openPopup(promoUnauthorizedPopup)
    }

    if (initData.auth.isAuth) {
      getMatchesForAuth().then(() => {
        initPredictEvent()
      })
    } else {
      getMatches().then(() => {
        initPredictEvent()
      })
    }
  })
}

getSettings().then(() => {
  initMatches()
})
