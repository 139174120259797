import { checkAuthCookies } from '@modules/auth/auth-cookies'
import { setAuthPage } from '@modules/auth/set-auth'
import { pariLogin } from '@modules/auth/use-pari-login'

const $authButtons: NodeListOf<HTMLAnchorElement> =
  document.querySelectorAll('[data-auth-button]')!

if (checkAuthCookies()) {
  setAuthPage()
}

if ($authButtons) {
  $authButtons.forEach(($authButton: HTMLAnchorElement) => {
    $authButton.addEventListener('click', async (event) => {
      event.preventDefault()

      try {
        await pariLogin()

        setAuthPage()

        // await initTable()
      } catch (error) {
        console.log(error)
      }
    })
  })
}
