import { setIsShowTimer } from '@modules/timer'

import { formDisabledBtns } from './form-handlers'
import { initData, textBtn } from './init-state'
import { setCardsFontSize } from './settings'

const form = document.querySelector('.form')
const submitBtn = document.querySelector('.form__submit-btn')
const resultBtn = document.querySelector('.aside__result-btn')
const winnersBtn = document.querySelector('.aside__btn-winners')
const authBtn = document.querySelector('.aside__btn-auth')

const matchTemplate = document.querySelector('#form-match')
const matchList = form.querySelector('.form__list')
const asideWrapper = document.querySelector('.aside__wrapper')
const asideInfo = document.querySelector('.aside__info')
const asideAnnouncement = document.querySelector('.aside__announcement')
const timerText = document.querySelector('.aside__timer-text')

const setShowWinnerField = (element, isWin) => {
  element.dataset.marker = 'true'

  isWin && element.dataset.marker
    ? element.classList.add('form__match--win')
    : element.classList.add('form__match--lose')
}

const setMatchData = (element, id, isSelect, isWinner) => {
  element.dataset.id = id
  element.dataset.select = isSelect
  if (initData.auth.isAuth) {
    element.dataset.winner = isWinner
  }

  if (isWinner && !initData.isWinnersPresent) {
    initData.isWinnersPresent = true
    asideAnnouncement.style.display = 'none'
  }

  if (!element.parentNode.marker) {
    if (isWinner && isSelect) {
      setShowWinnerField(element.parentNode, true)
    }

    if (isWinner && !isSelect) {
      setShowWinnerField(element.parentNode, false)
    }
  }
}

const setMatchContent = (element, name, imgPath) => {
  const nameNode = element.querySelector('.form__name')
  const imgNode = element.querySelector('.form__img')
  nameNode.textContent = name
  if (imgPath) imgNode.style.backgroundImage = `url('${imgPath}')`
}

export const checkSelect = () => {
  const formMatches = form.querySelectorAll('.form__match')
  const selectsTotal = formMatches.length
  let isAllSelects = true
  let rightSelectsCounter = 0

  formMatches.forEach((item) => {
    const selects = item.querySelectorAll('button')
    let isSelect = false

    selects.forEach((select) => {
      if (select.dataset.select === 'true') {
        isSelect = true

        if (select.dataset.winner === 'true') rightSelectsCounter++
      }
    })

    if (!isSelect) {
      isAllSelects = false
    }
  })

  if (selectsTotal < 7) asideWrapper.classList.add('aside__wrapper--medium')
  else asideInfo.classList.add('aside__info--small')

  if (selectsTotal === 6) asideWrapper.classList.add('aside__wrapper--big')
  return { isAllSelects, rightSelectsCounter, selectsTotal }
}

export const setAvailableVote = () => {
  const { isAllSelects, rightSelectsCounter, selectsTotal } = checkSelect()

  if (initData.isWinnersList) {
    asideAnnouncement.style.display = 'none'
  }

  if (
    initData.voteDate.getTime() - initData.nowDate.getTime() <= 0 ||
    initData.isWinnersPresent
  ) {
    submitBtn.style.display = 'none'
    resultBtn.style.display = 'block'
    formDisabledBtns()
    asideInfo.classList.add('aside__info--small')
    if (isAllSelects) {
      if (initData.isWinnersList) {
        resultBtn.textContent = textBtn.accept
        const resultSpan = document.createElement('span')
        resultSpan.classList.add('aside__result-score')
        resultSpan.textContent = `Ты угадал ${rightSelectsCounter} из ${selectsTotal}`
        resultBtn.appendChild(resultSpan)
      } else {
        resultBtn.textContent = textBtn.notVote
      }

      submitBtn.style.display = 'none'
      resultBtn.style.display = 'block'
      setIsShowTimer(true)
      form.classList.add('form--finished')
    } else {
      // прогнозы приняты, таймер истек, победители не определены
      resultBtn.textContent = textBtn.notVote
      timerText.textContent = 'до начала матча'
      // asideAnnouncement.style.display = 'block'
      submitBtn.style.display = 'none'
      resultBtn.style.display = 'block'
      form.classList.add('form--not-vote')
      if (initData.auth.isAuth) {
        setIsShowTimer(true)
      } else {
        setIsShowTimer(false)
        asideInfo.classList.add('aside__info--no-auth')
        authBtn.style.display = 'block'
      }
    }

    if (initData.isWinnersList) {
      setIsShowTimer(false)
      winnersBtn.style.display = 'block'
    }
  } else if (isAllSelects) {
    submitBtn.disabled = true
    resultBtn.textContent = textBtn.notVote
    submitBtn.style.display = 'none'
    resultBtn.style.display = 'block'
    asideInfo.classList.add('aside__info--small')
    formDisabledBtns()
    initData.isVoteAvailable = true
    setIsShowTimer(true)
    form.classList.add('form--finished')
  } else {
    form.classList.add('form--vote-started')
    initData.isVoteAvailable = true
    setIsShowTimer(true)
  }
}

export const addMatches = (match) => {
  const matchId = match.block

  const drawCommand =
    Object.keys(match.draw_command).length === 0 ? false : match.draw_command
  const firstCommand = match.first_command
  const secondCommand = match.two_command

  const matchBlock = matchTemplate.content.cloneNode(true)
  const matchItem = matchBlock.querySelector('li')

  matchItem.dataset.id = matchId
  const formDraw = matchItem.querySelector('.form__command--center')
  const formFirstCommand = matchItem.querySelector('.form__command--left')
  const formSecondCommand = matchItem.querySelector('.form__command--right')

  drawCommand
    ? setMatchData(
        formDraw,
        drawCommand.id,
        drawCommand.select,
        drawCommand.winner,
      )
    : formDraw.remove()

  setMatchData(
    formFirstCommand,
    firstCommand.id,
    firstCommand.select,
    firstCommand.winner,
  )
  setMatchContent(formFirstCommand, firstCommand.name, firstCommand.logo)

  setMatchData(
    formSecondCommand,
    secondCommand.id,
    secondCommand.select,
    secondCommand.winner,
  )
  setMatchContent(formSecondCommand, secondCommand.name, secondCommand.logo)

  matchList.append(matchBlock)
  setCardsFontSize(initData.desktopFontSize, initData.mobileFontSize)
}
