import { initData } from './init-state'
import { checkSelect } from './matches'
import { openPopup } from './popup'

const form = document.querySelector('.form')
const submitBtn = document.querySelector('.form__submit-btn')
const popupUnauthorized = document.querySelector('#unauthorized')

const setMatchSelect = (match, activeCommand) => {
  const commandList = match.children

  for (let index = 0; index < commandList.length; index++) {
    commandList[index].dataset.select = 'false'
  }

  activeCommand.dataset.select = 'true'
}

const selectorHandler = (evt) => {
  evt.preventDefault()

  if (!initData.isVoteAvailable) {
    /* empty */
  } else if (initData.auth.isAuth) {
    const command = evt.target
    const match = command.parentNode //form__match

    setMatchSelect(match, command)
    const { isAllSelects } = checkSelect()
    if (isAllSelects) {
      submitBtn.disabled = false
      submitBtn.style.display = 'block'
    }
  } else {
    openPopup(popupUnauthorized)
  }
}

export const formBtnHandlers = () => {
  const formList = form.querySelector('.form__list')
  const matchBtns = formList.querySelectorAll('button')

  matchBtns.forEach((selector) => {
    selector.addEventListener('click', selectorHandler)
  })
}

export const formDisabledBtns = () => {
  const formList = form.querySelector('.form__list')
  const matchBtns = formList.querySelectorAll('button')

  matchBtns.forEach((selector) => {
    selector.disabled = true
  })
}
