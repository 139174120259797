export const getMobileSnake = (
  count,
) => `<svg width="280" height="1985" viewBox="0 0 280 1985" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M140 206H176.25C225.265 206 265 245.735 265 294.75V294.75C265 343.765 225.265 383.5 176.25 383.5H98.75C49.7347 383.5 10 423.235 10 472.25V472.25C10 521.265 49.7347 561 98.75 561L144.5 561" stroke="url(#paint0_linear_1_2)" stroke-width="4"/>
</g>
<!-- 4 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M139 561H175.5C224.377 561 264 600.623 264 649.5V649.5C264 698.377 224.377 738 175.5 738H139" stroke="url(#paint0_linear_132_355)" stroke-width="4"/>
</g>
<!-- 5 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M139 561H175.5C224.377 561 264 600.623 264 649.5V649.5C264 698.377 224.377 738 175.5 738H97.5C48.6228 738 9 777.623 9 826.5V826.5C9 875.377 48.6228 915 97.5 915L143.5 915" stroke="url(#paint0_linear_132_300)" stroke-width="4"/>
</g>
<!-- 6 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M139 916H175.25C224.265 916 264 955.735 264 1004.75V1004.75C264 1053.77 224.265 1093.5 175.25 1093.5H139" stroke="url(#paint0_linear_132_241)" stroke-width="4"/>
</g>
<!-- 7 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M139 916H175.25C224.265 916 264 955.735 264 1004.75V1004.75C264 1053.77 224.265 1093.5 175.25 1093.5H97.75C48.7347 1093.5 9 1133.23 9 1182.25V1182.25C9 1231.27 48.7347 1271 97.75 1271L143.5 1271" stroke="url(#paint0_linear_132_175)" stroke-width="4"/>
</g>
<!-- 8 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M142 1270H178.5C227.377 1270 267 1309.62 267 1358.5V1358.5C267 1407.38 227.377 1447 178.5 1447H134" stroke="url(#paint0_linear_132_150)" stroke-width="4"/>
</g>
<!-- 9 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M143 1271H179.5C228.377 1271 268 1310.62 268 1359.5V1359.5C268 1408.38 228.377 1448 179.5 1448H101.5C52.6228 1448 13 1487.62 13 1536.5V1536.5C13 1585.38 52.6228 1625 101.5 1625L147.5 1625" stroke="url(#paint0_linear_132_27)" stroke-width="4"/>
</g>
<!-- 10 -->
<g filter="url(#filter0_ddddddf_1_2)">
<path d="M146 1625H182.5C231.377 1625 271 1664.62 271 1713.5V1713.5C271 1762.38 231.377 1802 182.5 1802H138" stroke="url(#paint0_linear_132_75)" stroke-width="4"/>
</g>
<defs>
<filter id="filter0_ddddddf_1_2" x="-2002.96" y="-1806.96" width="4280.92" height="4380.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
<feBlend in="SourceGraphic" mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1_2" result="shape"/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
</filter>
<filter id="filter1_ddddddf_1_2" x="-2003.96" y="-1451.96" width="4280.92" height="4379.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
<feBlend in="SourceGraphic" mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1_2" result="shape"/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
</filter>
<filter id="filter2_ddddddf_1_2" x="-2003.96" y="-1096.96" width="4280.92" height="4380.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
<feBlend in="SourceGraphic" mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1_2" result="shape"/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
</filter>
<filter id="filter3_ddddddf_1_2" x="-1999.96" y="-741.96" width="4280.92" height="4379.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
<feBlend in="SourceGraphic" mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1_2" result="shape"/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
</filter>
<filter id="filter4_ddddddf_1_2" x="-1872.96" y="-387.96" width="4156.92" height="4202.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
<feBlend in="SourceGraphic" mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
<feBlend in="SourceGraphic" mode="normal" in="SourceGraphic" in2="effect6_dropShadow_1_2" result="shape"/>
<feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
</filter>
<linearGradient className='three'  id="paint0_linear_1_2" x1="10" y1="206" x2="304.252" y2="242.233" gradientUnits="userSpaceOnUse">
<stop stop-color="#753BBD" stop-opacity="0"/>
<stop offset="0.0689053" stop-color="#6D45BC"/>
<stop offset="0.905611" stop-color="#0BBAB2"/>
<stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
</linearGradient>
${
  count > 3 &&
  `<linearGradient id="paint0_linear_132_355" x1="8.99999" y1="561" x2="303.227" y2="597.332" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
}
${
  count > 4 &&
  `<linearGradient id="paint0_linear_132_300" x1="9" y1="561" x2="303.227" y2="597.332" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
}
${
  count > 5 &&
  `<linearGradient id="paint0_linear_132_241" x1="9" y1="916" x2="303.252" y2="952.233" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
}
  ${
    count > 6 &&
    `<linearGradient id="paint0_linear_132_175" x1="9" y1="916" x2="303.252" y2="952.233" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
  }
  ${
    count > 7 &&
    `<linearGradient id="paint0_linear_132_150" x1="12" y1="1270" x2="306.227" y2="1306.33" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
  }
  ${
    count > 8 &&
    `<linearGradient id="paint0_linear_132_27" x1="13" y1="1271" x2="307.227" y2="1307.33" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
  }
  ${
    count > 9 &&
    `<linearGradient id="paint0_linear_132_75" x1="16" y1="1625" x2="310.227" y2="1661.33" gradientUnits="userSpaceOnUse">
  <stop stop-color="#753BBD" stop-opacity="0"/>
  <stop offset="0.0689053" stop-color="#6D45BC"/>
  <stop offset="0.905611" stop-color="#0BBAB2"/>
  <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
  }
</defs>
</svg>`

export const getDesktopSnake = (count) =>
  `<svg width="1232" height="1193" viewBox="0 0 1232 1193" fill="none" xmlns="http://www.w3.org/2000/svg">
  <!-- 3 -->
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H960" stroke="url(#paint0_linear_189_19)" stroke-width="4"/>
    </g>
    
  <!-- 4 -->
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H790.5" stroke="url(#paint0_linear_189_65)" stroke-width="4"/>
    </g>
    
  <!-- 5 -->
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H436" stroke="url(#paint0_linear_189_111)" stroke-width="4"/>
    </g>
  
  <!-- 6 -->
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H164C98.8304 453 46 505.83 46 571V571C46 636.17 98.8304 689 164 689H264" stroke="url(#paint0_linear_189_157)" stroke-width="4"/>
    </g>
    
  <!-- 7 -->  
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H164C98.8304 453 46 505.83 46 571V571C46 636.17 98.8304 689 164 689H617.5" stroke="url(#paint0_linear_189_279)" stroke-width="4"/>
    </g>
  
  <!-- 8 -->
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H164C98.8304 453 46 505.83 46 571V571C46 636.17 98.8304 689 164 689H1186" stroke="url(#paint0_linear_189_218)" stroke-width="4"/>
    </g>
    
  <!-- 7-8 -->  
    <g filter="url(#filter0_ddddddf_1_2)">
      <path d="M46 217H1068C1133.17 217 1186 269.83 1186 335V335C1186 400.17 1133.17 453 1068 453H164C98.8304 453 46 505.83 46 571V571C46 636.17 98.8304 689 164 689H967" stroke="url(#paint0_linear_1_2)" stroke-width="4"/>
    </g>
  
  <!-- 7-9 -->
    <g filter="url(#filter0_ddddddf_1_2)">
			<path  d="M46 215L1068 215Q1092.41 215 1114.71 224.43Q1136.25 233.54 1152.85 250.14Q1169.46 266.75 1178.56 288.29Q1188 310.58 1188 335Q1188 359.41 1178.56 381.71Q1169.46 403.24 1152.85 419.85Q1136.25 436.46 1114.71 445.56Q1092.41 455 1068 455L164 455Q140.39 455 118.84 464.11Q98.03 472.92 81.97 488.97Q65.91 505.03 57.11 525.84Q48 547.39 48 571Q48 594.6 57.11 616.15Q65.91 636.96 81.97 653.02Q98.03 669.08 118.84 677.88Q140.39 687 164 687L967 687L967 691L164 691Q139.58 691 117.29 681.56Q95.75 672.46 79.14 655.85Q62.53 639.24 53.43 617.71Q44 595.41 44 571Q44 546.58 53.43 524.28Q62.53 502.75 79.14 486.14Q95.75 469.53 117.29 460.43Q139.58 451 164 451L1068 451Q1091.6 451 1113.15 441.88Q1133.97 433.08 1150.02 417.02Q1166.07 400.96 1174.88 380.15Q1184 358.6 1184 335Q1184 311.4 1174.88 289.84Q1166.07 269.03 1150.02 252.97Q1133.97 236.92 1113.15 228.11Q1091.6 219 1068 219L46 219L46 215Z" fill="url(#paint_linear_189_340_0)" fill-opacity="1.000000" fill-rule="evenodd"/>
		</g>
		<g filter="url(#filter0_ddddddf_1_2)">
			<path d="M964.5 687L1072.5 687Q1096.91 687 1119.21 696.43Q1140.75 705.54 1157.35 722.14Q1173.96 738.75 1183.06 760.29Q1192.5 782.58 1192.5 807Q1192.5 831.41 1183.06 853.71Q1173.96 875.24 1157.35 891.85Q1140.74 908.46 1119.21 917.56Q1096.91 927 1072.5 927L792 927L792 923L1072.5 923Q1096.1 923 1117.65 913.88Q1138.47 905.08 1154.52 889.02Q1170.57 872.96 1179.39 852.15Q1188.5 830.6 1188.5 807Q1188.5 783.39 1179.39 761.84Q1170.57 741.03 1154.52 724.97Q1138.47 708.92 1117.65 700.11Q1096.1 691 1072.5 691L964.5 691L964.5 687Z" fill="url(#paint_linear_189_384_0)" fill-opacity="1.000000" fill-rule="evenodd"/>
		</g>
  <!-- 9-10 -->  
    <g filter="url(#filter1_ddddddf_1_2)">
      <path d="M964 689H1072C1137.17 689 1190 741.83 1190 807V807C1190 872.17 1137.17 925 1072 925H435.5" stroke="url(#paint1_linear_1_2)" stroke-width="4"/>
    </g>
    
  <defs>
  ${
    count > 8 &&
    `<filter id="filter0_ddddddf_1_2" x="-1966.96" y="-1795.96" width="5165.92" height="4497.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
      <feBlend mode="normal" in2="effect6_dropShadow_1_2" result="shape"/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
    </filter>`
  }
  
<!-- проблемная -->
    
    <!-- проблемная -->
    
    
    
    
    
    
    <filter id="filter1_ddddddf_1_2" x="-1575.46" y="-1323.96" width="4778.42" height="4261.92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="23.94"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="47.88"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_1_2" result="effect2_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="167.58"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow_1_2" result="effect3_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="335.16"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect3_dropShadow_1_2" result="effect4_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="574.56"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal" in2="effect4_dropShadow_1_2" result="effect5_dropShadow_1_2"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="1005.48"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0"/>
      <feBlend mode="normal"  in2="effect5_dropShadow_1_2" result="effect6_dropShadow_1_2"/>
      <feBlend mode="normal"   in2="effect6_dropShadow_1_2" result="shape"/>
      <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="effect7_foregroundBlur_1_2"/>
    </filter>


    <filter id="filter1_ddddddf_1_2" x="616.000000" y="-11935.000000" width="0.000000" height="0.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
			<feGaussianBlur stdDeviation="333.333" result="effect_layerBlur_1"/>
		</filter>
		<clipPath id="clip189_333">
			<rect id="pari_logo_on-dark_RGB 1" width="200.434082" height="45.203392" transform="translate(518.000000 32.000000)" fill="white" fill-opacity="0"/>
		</clipPath>
		<filter id="filter_189_340_dd" x="-1966.959961" y="-1795.959961" width="5165.919922" height="4497.919922" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="15.96"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="31.92"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="111.72"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_2" result="effect_dropShadow_3"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="223.44"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_3" result="effect_dropShadow_4"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="383.04"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_4" result="effect_dropShadow_5"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="670.32"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_5" result="effect_dropShadow_6"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_6" result="shape"/>
			<feGaussianBlur stdDeviation="0.666667" result="effect_layerBlur_7"/>
		</filter>
		<filter id="filter_189_384_dd" x="-1218.959961" y="-1323.959961" width="4422.419922" height="4261.919922" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="15.96"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="31.92"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="111.72"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_2" result="effect_dropShadow_3"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="223.44"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_3" result="effect_dropShadow_4"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="383.04"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_4" result="effect_dropShadow_5"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="0"/>
			<feGaussianBlur stdDeviation="670.32"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.41961 0 0 0 0 0.27843 0 0 0 0 0.73725 0 0 0 1 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_5" result="effect_dropShadow_6"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_6" result="shape"/>
			<feGaussianBlur stdDeviation="0.666667" result="effect_layerBlur_7"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="224.000000" y="181.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="400.000000" y="417.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="756.000000" y="890.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="224.000000" y="653.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="576.000000" y="653.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="928.000000" y="653.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="752.000000" y="417.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="576.000000" y="181.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="928.000000" y="181.000000" width="80.000000" height="80.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="8"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.4 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="4"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.78039 0 0 0 0 0.69412 0 0 0 0.25 0"/>
			<feBlend mode="normal" in2="effect_dropShadow_1" result="effect_dropShadow_2"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_2" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="28.000000" y="203.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="380.000000" y="203.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="732.000000" y="203.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="204.000000" y="439.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="556.000000" y="439.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="624.000000" y="972.000000" width="344.000000" height="144.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="28.000000" y="675.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="380.000000" y="675.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<filter id="filter1_ddddddf_1_2" x="732.000000" y="675.000000" width="472.000000" height="272.000000" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
			<feFlood flood-opacity="0" result="BackgroundImageFix"/>
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
			<feOffset dx="0" dy="4"/>
			<feGaussianBlur stdDeviation="21.3333"/>
			<feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
			<feColorMatrix type="matrix" values="0 0 0 0 0.35686 0 0 0 0 0.36078 0 0 0 0 0.73333 0 0 0 0.15 0"/>
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
			<feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
		</filter>
		<clipPath id="clip189_325">
			<rect id="Pop-up" rx="24.000000" width="1232.000000" height="1193.000000" fill="white" fill-opacity="0"/>
		</clipPath>
    <linearGradient id="paint0_linear_189_19" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
      <stop stop-color="#753BBD" stop-opacity="0"/>
      <stop offset="0.0689053" stop-color="#6D45BC"/>
      <stop offset="0.905611" stop-color="#0BBAB2"/>
      <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
    </linearGradient>
  ${
    count > 3 &&
    `<linearGradient id="paint0_linear_189_65" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
      <stop stop-color="#753BBD" stop-opacity="0"/>
      <stop offset="0.0689053" stop-color="#6D45BC"/>
      <stop offset="0.905611" stop-color="#0BBAB2"/>
      <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
    </linearGradient>`
  }
  ${
    count > 4 &&
    `<linearGradient id="paint0_linear_189_111" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
      <stop stop-color="#753BBD" stop-opacity="0"/>
      <stop offset="0.0689053" stop-color="#6D45BC"/>
      <stop offset="0.905611" stop-color="#0BBAB2"/>
      <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
    </linearGradient>`
  }
  ${
    count > 5 &&
    `<linearGradient id="paint0_linear_189_157" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
      <stop stop-color="#753BBD" stop-opacity="0"/>
      <stop offset="0.0689053" stop-color="#6D45BC"/>
      <stop offset="0.905611" stop-color="#0BBAB2"/>
    <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
    </linearGradient>`
  }
${
  count > 6 &&
  `<linearGradient id="paint0_linear_189_279" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
    <stop stop-color="#753BBD" stop-opacity="0"/>
    <stop offset="0.0689053" stop-color="#6D45BC"/>
    <stop offset="0.905611" stop-color="#0BBAB2"/>
    <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
    </linearGradient>`
}
${
  count === 8 &&
  `<linearGradient id="paint0_linear_189_218" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
    <stop stop-color="#753BBD" stop-opacity="0"/>
    <stop offset="0.0689053" stop-color="#6D45BC"/>
    <stop offset="0.905611" stop-color="#0BBAB2"/>
    <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
}
${
  count > 8 &&
  `<linearGradient x1="46.000061" y1="217.000122" x2="1186.000000" y2="689.000122" id="paint_linear_189_340_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD" stop-opacity="0.000000"/>
			<stop offset="0.068905" stop-color="#6D45BC"/>
			<stop offset="0.905611" stop-color="#0BBAB2"/>
			<stop offset="1.000000" stop-color="#00C7B1" stop-opacity="0.000000"/>
		</linearGradient>
		<linearGradient x1="51.430359" y1="689.000122" x2="1190.000000" y2="1160.999878" id="paint_linear_189_384_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD" stop-opacity="0.000000"/>
			<stop offset="0.068905" stop-color="#6D45BC"/>
			<stop offset="0.905611" stop-color="#0BBAB2"/>
			<stop offset="1.000000" stop-color="#00C7B1" stop-opacity="0.000000"/>
		</linearGradient>
		<linearGradient x1="264.000000" y1="201.000000" x2="264.000000" y2="233.000000" id="paint_linear_189_342_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="440.000000" y1="437.000000" x2="440.000000" y2="469.000000" id="paint_linear_189_345_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="796.000000" y1="910.000000" x2="796.000000" y2="942.000000" id="paint_linear_189_391_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="264.000000" y1="673.000000" x2="264.000000" y2="705.000000" id="paint_linear_189_348_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="616.000000" y1="673.000000" x2="616.000000" y2="705.000000" id="paint_linear_189_351_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="968.000000" y1="673.000000" x2="968.000000" y2="705.000000" id="paint_linear_189_354_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="792.000000" y1="437.000000" x2="792.000000" y2="469.000000" id="paint_linear_189_357_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="616.000000" y1="201.000000" x2="616.000000" y2="233.000000" id="paint_linear_189_360_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="968.000000" y1="201.000000" x2="968.000000" y2="233.000000" id="paint_linear_189_363_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#00C7B1"/>
			<stop offset="1.000000" stop-color="#0A9888"/>
		</linearGradient>
		<linearGradient x1="92.000000" y1="262.999908" x2="436.000000" y2="407.000031" id="paint_linear_189_366_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD"/>
			<stop offset="1.000000" stop-color="#00C7B1"/>
		</linearGradient>
		<linearGradient x1="268.000000" y1="498.999908" x2="612.000000" y2="643.000000" id="paint_linear_189_373_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD"/>
			<stop offset="1.000000" stop-color="#00C7B1"/>
		</linearGradient>
		<linearGradient x1="624.000000" y1="971.999878" x2="968.000000" y2="1116.000000" id="paint_linear_189_393_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD"/>
			<stop offset="1.000000" stop-color="#00C7B1"/>
		</linearGradient>
		<linearGradient x1="92.000000" y1="734.999878" x2="436.000000" y2="879.000000" id="paint_linear_189_378_0" gradientUnits="userSpaceOnUse">
			<stop stop-color="#753BBD"/>
			<stop offset="1.000000" stop-color="#00C7B1"/>
		</linearGradient>`
}
${
  count > 9 &&
  `<linearGradient id="paint0_linear_1_2" x1="46" y1="217" x2="1186" y2="689" gradientUnits="userSpaceOnUse">
    <stop stop-color="#753BBD" stop-opacity="0"/>
    <stop offset="0.0689053" stop-color="#6D45BC"/>
    <stop offset="0.905611" stop-color="#0BBAB2"/>
    <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>
  <linearGradient id="paint1_linear_1_2" x1="50" y1="689" x2="1190" y2="1161" gradientUnits="userSpaceOnUse">
    <stop stop-color="#753BBD" stop-opacity="0"/>
    <stop offset="0.0689053" stop-color="#6D45BC"/>
    <stop offset="0.905611" stop-color="#0BBAB2"/>
    <stop offset="1" stop-color="#00C7B1" stop-opacity="0"/>
  </linearGradient>`
}
</defs>
</svg>
`
