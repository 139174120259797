import { host, initData } from '../init-state'
import { setWinnersPopup } from '../winners-popup'

// const winnersMatchName = document.querySelector('.winners__match-name')

const getWinners = () => {
  fetch(`${host}/winners`)
    .then((response) => {
      if (response.status === 200) return response.json()
      return false
    })
    .then((result) => {
      // winnersMatchName.textContent = result.data.name

      const [...data] = result.data.items
      if (Object.keys(data).length) {
        setWinnersPopup(data)
        initData.isWinnersList = true
      }
    })
    .catch(() => console.log('no auth'))
}

getWinners()
