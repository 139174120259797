const links: NodeListOf<HTMLAnchorElement> =
  document.querySelectorAll('a:not([href^="#"])')

const queries = window.location.search.replace('?', '&')

links.forEach(utmHandler)

export function utmHandler(link: HTMLAnchorElement) {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    if (event.currentTarget instanceof HTMLAnchorElement) {
      const query =
        (event.currentTarget?.href.search(/\?/g) > 0 ? '&' : '?') +
        'partner=pb' +
        queries

      const link = event.currentTarget.href + query

      if (event.currentTarget.href) {
        if (event.currentTarget.target === '_blank') {
          window.open(link, '_blank')
        } else {
          window.location.href = link
        }
      }
    }
  })
}
