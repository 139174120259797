import { formBtnHandlers } from '../form-handlers'
import { host, initData } from '../init-state'
import { addMatches, setAvailableVote } from '../matches'
import { openPopup } from '../popup'
import { startTimer } from '../timer'

import { initPredictEvent } from './predict'

const popupError = document.querySelector('#accepted-error')

export const getMatchesForAuth = async () => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('customerid', initData.auth.cid)
  headers.append('fsid', initData.auth.fsid)

  await fetch(`${host}/forecasts`, {
    method: 'GET',
    headers: headers,
  })
    .then((response) => {
      const status = response.status
      if (status === 200) {
        return response.json()
      } else if (status === 403) {
        // todo: на время тестов можно закоммитить
        document.body.classList.add('not-available')
      } else {
        throw 'Данные по матчу не получены'
      }
    })
    .then((result) => {
      if (Object.keys(result.data).length) {
        const resultData = result.data
        const matches = resultData.commands
        const voteDate = resultData.date_start
        const nowDate = resultData.date_now

        initData.matchId = resultData.id
        initData.voteDate = new Date(voteDate.replace(/-/g, '/'))
        initData.nowDate = new Date(nowDate.replace(/-/g, '/'))

        matches.forEach((match) => {
          addMatches(match)
          formBtnHandlers()
        })

        startTimer()
        setAvailableVote()
      } else {
        if (!initData.isAlredyLoadMatches) {
          getMatches().then(() => {
            if (initData.isVoteAvailable) initPredictEvent()
          })
        }
      }
    })
    .catch((error) => console.log('Error ' + error))
}

export const getMatches = async () => {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${initData.auth.token}`)

  await fetch(`${host}/matches`, {
    method: 'GET',
    headers: headers,
  })
    .then((response) => {
      const status = response.status
      if (status === 200) {
        return response.json()
      } else if (status === 403) {
        document.body.classList.add('not-available')
      } else {
        throw 'Данные по матчу не получены'
      }
    })
    .then((result) => {
      if (result.data) {
        const resultData = result.data

        const matches = resultData.commands
        const voteDate = resultData.date_start
        const nowDate = resultData.date_now

        initData.matchId = resultData.id
        initData.voteDate = new Date(voteDate.replace(/-/g, '/'))
        initData.nowDate = new Date(nowDate.replace(/-/g, '/'))

        matches.forEach((match) => {
          addMatches(match)
          formBtnHandlers()
          startTimer()
        })
        setAvailableVote()
        initData.isAlredyLoadMatches = true
      } else {
        openPopup(popupError)
      }
    })
    .catch((error) => console.log('Error ' + error))
  // }
}
