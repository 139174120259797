import { updateDomContent } from '@modules/settings'

import { host, hostPromo } from '../init-state'
import { webviewCheck } from '../webview-check'

export const getSettings = async () => {
  webviewCheck()

  Promise.all([
    await fetch(`${host}/settings`, {
      method: 'GET',
    }).then((result) => result.json()),
    await fetch(`${hostPromo}/totalizator-guides`, {
      method: 'GET',
    }).then((result) => result.json()),
  ])
    .then((response) => {
      const [first, second] = response
      updateDomContent({ ...first, ...second })
    })
    .catch((error) => {
      console.log('Error ' + error)
    })
}
