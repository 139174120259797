import { overlayScrollbarRules } from '@modules/scrollbar'

import { getDesktopSnake, getMobileSnake } from './create-snake'
import { initData, textBtn } from './init-state'
import { isMobile } from './util'

const billboard = document.querySelector('.billboard')

const promo = document.querySelector('.promo')
const pictureTemplate = document.querySelector('#template-picture')
const billboardWrapper = billboard.querySelector('.billboard__image')
const billboardDescription = billboard.querySelector('.billboard__description')

const billboardTitles = billboard.querySelectorAll('.billboard__text')
const billboardInstruction = billboard.querySelector('.billboard__instruction')
const billboardAnnotation = billboard.querySelector('.billboard__annotation')
const rulesWrapper = document.querySelector('.popup__rules-wrapper')
const rulesContainerPromo = document.querySelector('.popup__container-promo')
const rulesBntNode = document.querySelector('#btn-rules-js')

const popupUnauthorizedTitle = document.querySelector(
  '#unauthorized .popup__title',
)
const popupUnauthorizedPromoTitle = document.querySelector(
  '#promo-unauthorized .popup__title',
)

const timerTitle = document.querySelector('.aside__timer-text')
const landingBtns = document.querySelectorAll('[data-btn]')
const unavailableTexts = document.querySelectorAll('[data-title-text]')
const forecastAcceptedTitle = document.querySelector('#accepted .popup__title')
const forecastAcceptedText = document.querySelector('#accepted .popup__text')
const forecastAcceptedBtn = document.querySelector(
  '#accepted .popup__authorization',
)
const forecastNoWinnersTitle = document.querySelector(
  '#no-winners .popup__title',
)

const announcementBtn = document.querySelector('.aside__announcement')
const announcementPopup = document.querySelector('#announcement')
const announcementTitle = announcementPopup.querySelector('.popup__title')
const announcementText = announcementPopup.querySelector('.popup__text')

const winnersTitle = document.querySelector('.winners__title')

const setPromoImages = (kvDesktop, kvMobile) => {
  const matchBlock = pictureTemplate.content.cloneNode(true)

  const source = matchBlock.querySelector('source')
  const img = matchBlock.querySelector('img')

  kvDesktop ? (source.srcset = kvDesktop) : null
  kvMobile
    ? (img.src = kvMobile)
    : billboardDescription.classList.add('billboard__description--no-image')

  billboardWrapper.append(matchBlock)
}

const setTextPromo = (contentData) => {
  const [{ text_1, text_2, text_3, description, footnote }] = contentData
  billboardTitles[0].textContent = text_1
  billboardTitles[1].textContent = text_2
  billboardTitles[2].textContent = text_3
  billboardInstruction.textContent = description
  billboardAnnotation.textContent = footnote
}

const setPromoBg = (bgDesktop, bgMobile) => {
  isMobile()
    ? (promo.style.backgroundImage = `url("${bgMobile}")`)
    : (promo.style.backgroundImage = `url("${bgDesktop}")`)

  window.addEventListener('resize', () => {
    if (initData.isMobile !== isMobile()) {
      initData.isMobile = isMobile()
      setPromoBg(bgDesktop, bgMobile)
      setCardsFontSize(initData.desktopFontSize, initData.mobileFontSize)
    }
  })
}

const setRules = (rulesString) => {
  const parser = new DOMParser()
  const rulesNode = parser.parseFromString(rulesString, 'text/html')

  const rulesContent = rulesNode.body.children

  for (let idx = 0; idx <= rulesContent.length - 1; idx++) {
    rulesWrapper.append(rulesContent[idx].cloneNode(true))
  }

  overlayScrollbarRules.update()
}

const setRulesPromo = (rules, settings) => {
  let heading = document.createElement('h2')
  heading.classList.add('promo__heading')
  heading.textContent = settings.title

  let rulesWrapperPromo = document.createElement('div')
  rulesWrapperPromo.classList.add('popup__rules-wrapper-promo')

  let snakeEl = document.createElement('div')
  snakeEl.classList.add('promo__snake')

  if (!settings.is_show) rulesBntNode.remove()

  if (isMobile()) {
    snakeEl.innerHTML = getMobileSnake(rules.length)
  } else {
    snakeEl.innerHTML = getDesktopSnake(rules.length)
  }
  rulesWrapperPromo.appendChild(snakeEl)

  let ruleNode = document.createElement('div')
  ruleNode.classList.add('promo__item')

  let orderEl = document.createElement('div')
  orderEl.classList.add('promo__item-order')
  let orderString = document.createElement('p')
  orderEl.appendChild(orderString)

  let titleEl = document.createElement('div')
  titleEl.classList.add('promo__item-title-wrap')
  let innerEl = document.createElement('div')
  innerEl.classList.add('promo__item-title')
  let titleString = document.createElement('p')

  innerEl.appendChild(titleString)
  titleEl.appendChild(innerEl)

  ruleNode.appendChild(orderEl)
  ruleNode.appendChild(titleEl)

  rules.forEach((rule, i) => {
    orderString.textContent = i + 1
    titleString.textContent = rule.title
    rulesWrapperPromo.append(ruleNode.cloneNode(true))
  })

  rulesContainerPromo.append(heading)
  rulesContainerPromo.append(rulesWrapperPromo)

  overlayScrollbarRules.update()
}

const setButtonsTitles = (authText, timerText) => {
  popupUnauthorizedTitle.textContent = authText
  popupUnauthorizedPromoTitle.textContent = authText
  timerTitle.textContent = timerText
}

const setButtonsText = (texts) => {
  setButtonsTitles(texts.popup_auth_text, texts.start_match)
  textBtn.predict = texts.create_forecast
  textBtn.notVote = texts.did_not_vote
  textBtn.accept = texts.forecast_accepted_btn
  textBtn.auth = texts.popup_auth_btn
  textBtn.winner = texts.text_winner_btn
  // textBtn.main = texts.forecast_is_accepted_link_site

  landingBtns.forEach((btnElement) => {
    Object.keys(textBtn).forEach((name) => {
      if (btnElement.dataset.btn === name) {
        btnElement.textContent = textBtn[name]
      }
    })
  })
}

const setUnvailabilityText = (text) => {
  unavailableTexts.forEach((textElement) => {
    if (textElement.dataset.titleText === 'top') {
      textElement.textContent = text.text_1
    }
    if (textElement.dataset.titleText === 'bottom') {
      textElement.textContent = text.text_2
    }
  })
}

const setPopupsText = (texts) => {
  forecastNoWinnersTitle.textContent = texts.forecast_is_accepted_text
  winnersTitle.textContent = texts.text_list_winners
}

const setPopupAcceptedText = (texts) => {
  forecastAcceptedTitle.textContent = texts.head
  forecastAcceptedText.textContent = texts.text
  forecastAcceptedBtn.textContent = texts.text_btn
}

const setNotifiedStartMatch = (texts) => {
  announcementBtn.textContent = texts.name_btn
  announcementTitle.textContent = texts.title
  announcementText.textContent = texts.text
}

export const setCardsFontSize = (desktopFontSize, mobileFontSize) => {
  const elements = document.querySelectorAll('.form__command')
  elements.forEach((element) => {
    const elementCenter = element.classList.contains('form__command--center')
    if (!elementCenter)
      if (isMobile()) {
        element.style.fontSize = `${mobileFontSize}rem`
      } else {
        element.style.fontSize = `${desktopFontSize}rem`
      }
  })
}

export const updateDomContent = async (data) => {
  initData.isVoteNotAvailableAll = !data.visible_for_all

  setRules(data.rules)
  setRulesPromo(data.guides, data.settings)
  setPromoImages(data.kv_desktop, data.kv_mobile)
  setPromoBg(data.kv_background, data.kv_background_mobile)
  setTextPromo(data.main_head)
  setButtonsText(...data.buttons_text)
  setUnvailabilityText(...data.unavaible_stock_text)
  setPopupsText(...data.buttons_text)
  setPopupAcceptedText(...data.popup_success_forecast)
  setNotifiedStartMatch(...data.popup_notified_start_match)

  initData.desktopFontSize = data.font_size_desktop
  initData.mobileFontSize = data.font_size_mobile
}
