import { clearCookies } from '@modules/auth/auth-cookies'
import { closePopup } from '@modules/popup'

import { authHost, host, initData } from '../init-state'

export const authLogin = async () => {
  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append('customerid', initData.auth.cid)
  headers.append('fsid', initData.auth.fsid)

  const requestOptions = {
    method: 'POST',
    headers: headers,
  }

  await fetch(`${authHost}/login`, requestOptions)
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          closePopup()
        }, 0)
        return (initData.auth.isAuth = true)
      }
      clearCookies()
      if (response.status === 401) return (initData.auth.isAuth = false)
      throw Error
    })
    .catch(() => console.error('auth error'))
}

// todo: старый метод
export const checkAuth = async () => {
  const headers = new Headers()
  const formData = new FormData()
  // let token = initData.auth.token

  formData.append('cid', initData.auth.cid)
  formData.append('fsid', initData.auth.fsid)
  // initData.auth.fsid ?
  //     headers.append('Authorization', `Bearer ${token}`) :
  //     headers.append('Authorization', null)

  headers.append('Accept', 'application/json')

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow',
  }

  if (token && initData.auth.cid) {
    await fetch(`${host}/validate-user/`, requestOptions)
      .then((response) => {
        if (response.status === 200) return token
        else return authLogin()
      })
      .catch((err) => console.log(err))
  } else {
    await authLogin()
  }

  return initData.auth.token
}
