import { OverlayScrollbars } from 'overlayscrollbars'

// const scrollbarContainer = document.querySelector('.form__list-wrapper')
// const scrollbarWinnersContainer = document.querySelector('.scrollbar-wrapper')
const scrollbarWinnersContainer = document.querySelector(
  '.popup__wrapper .scrollbar-wrapper',
)
const scrollbarRulesContainer = document.querySelector('#rules .popup__text')
const scrollbarAcceptedWrapper = document.querySelector(
  '#accepted .popup__wrapper',
)

// todo: delete
// export const overlayScrollbar = OverlayScrollbars(scrollbarContainer, {})
export const overlayScrollbarWinners = OverlayScrollbars(
  scrollbarWinnersContainer,
  {},
)
export const overlayScrollbarRules = OverlayScrollbars(
  scrollbarRulesContainer,
  {},
)
export const overlayScrollbarAccepted = OverlayScrollbars(
  scrollbarAcceptedWrapper,
  {
    overflow: {
      x: 'visible',
    },
  },
)
