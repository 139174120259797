import { formDisabledBtns } from './form-handlers'
import { initData } from './init-state'

const timerWrapper = document.querySelector('.aside__timer-wrapper')
const daysElement = timerWrapper.querySelector('.aside__time--days')
const hoursElement = timerWrapper.querySelector('.aside__time--hours')
const minutesElement = timerWrapper.querySelector('.aside__time--minutes')
const secondsElement = timerWrapper.querySelector('.aside__time--seconds')
const winnersBtn = document.querySelector('.aside__btn-winners')
const submitBtn = document.querySelector('.form__submit-btn')

let timeCounter = 0
let timerPlayed = false

export const setIsShowTimer = (isShow) => {
  if (isShow) timerWrapper.style.display = 'flex'
  else {
    timerWrapper.style.display = 'none'
    if (initData.isWinnersList) winnersBtn.style.display = 'block'
  }
}

export const startTimer = () => {
  submitBtn.style.display = 'block'

  if (!timerPlayed) {
    timerPlayed = true
    const eventDay = initData.voteDate

    const timeBetweenDates = (eventDay) => {
      const thisTime = new Date(initData.nowDate.getTime() + timeCounter * 1000)
      timeCounter++
      const difference = eventDay.getTime() - thisTime.getTime()
      if (difference < 0) {
        clearInterval(timer)
        formDisabledBtns()
      } else {
        let seconds = Math.floor(difference / 1000)
        let minutes = Math.floor(seconds / 60)
        let hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)
        hours %= 24
        minutes %= 60
        seconds %= 60
        daysElement.textContent = `0${days}`.slice(-2)
        hoursElement.textContent = `0${hours}`.slice(-2)
        minutesElement.textContent = `0${minutes}`.slice(-2)
        secondsElement.textContent = `0${seconds}`.slice(-2)
      }
    }

    const timer = setInterval(() => {
      timeBetweenDates(eventDay)
    }, 1000)
  }
}
