import { overlayScrollbarWinners } from '@modules/scrollbar'

import { initData } from './init-state'
import { openPopup } from './popup'

const winnersBtn = document.querySelector('.aside__btn-winners')
const popupWinnersUnavailable = document.querySelector('#no-winners')
const winnersItem = document.querySelector('#winners-item')
const popupWinners = document.querySelector('#winners')

const appendListHandler = (data, wrapper, createList) => {
  const list = getMatchesList(wrapper)

  data.forEach((item) => {
    createList(item, list)
  })

  overlayScrollbarWinners.update()
}

const getMatchesList = (wrapper) => {
  const list = wrapper.querySelector('.winners__list')
  const listItems = list.querySelectorAll('li')
  listItems.forEach((item) => item.remove())

  return list
}

const createWinnersListItem = (item, list) => {
  const listElement = document.createElement('li')
  listElement.append(winnersItem.content.cloneNode(true))
  listElement.classList.add('winners__item')
  listElement.style.opacity = '1'
  const name = listElement.querySelector('.winners__name')
  const info = listElement.querySelector('.winners__info')
  name.textContent = item.cid
  info.textContent = item.what_gets
  list.append(listElement)
}

export const setWinnersPopup = (result) => {
  appendListHandler(result, popupWinners, createWinnersListItem)
}

const winnersBtnHandler = () => {
  initData.isWinnersList
    ? openPopup(popupWinners)
    : openPopup(popupWinnersUnavailable)
}

winnersBtn.addEventListener('click', winnersBtnHandler)
