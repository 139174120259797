import Cookies from 'js-cookie'

// export const authHost = `https://pari-constructor-matches-dev.24preprod.ru/api/auth`
// export const host = `https://pari-constructor-matches-dev.24preprod.ru/api/totalizator/3`
// dev admin

const numberLanding = 1

// prod
// export const authHost = `https://constructor-matches.pari-api.ru/api/auth`
// export const host = `https://constructor-matches.pari-api.ru/api/totalizator/${numberLanding}`
// export const hostPromo = `https://constructor-matches.pari-api.ru/api/${numberLanding}`

// dev
export const authHost = `https://pari-match-constructor.pari.air-tech.agency/api/auth`
export const host = `https://pari-match-constructor.pari.air-tech.agency/api/totalizator/${numberLanding}`
export const hostPromo = `https://pari-match-constructor.pari.air-tech.agency/api/${numberLanding}`

export const initData = {
  auth: {
    isAuth: false,
    cid: Cookies.get('headerApi.cid'),
    fsid: Cookies.get('headerApi.FSID'),
  },
  webview: location.search.includes('webview'),
  voteDate: '',
  nowDate: '',
  matchId: null,
  isWinnersPresent: false,
  isVoteAvailable: false,
  isVoteNotAvailableAll: false,
  isMobile: true,
  isWinnersList: false,
  desktopFontSize: '25rem',
  mobileFontSize: '15rem',
  submitForecast: false,
  isAlredyLoadMatches: false,
}

export const textBtn = {
  predict: '',
  notVote: '',
  accept: '',
  auth: '',
  winner: '',
  result: 'Результаты',
  resultText: 'Ты угадал',
}

export const setInitAuth = () => {
  initData.auth.cid = Cookies.get('headerApi.cid')
  initData.auth.fsid = Cookies.get('headerApi.FSID')

  // initData.auth.cid = prompt('Введи cid (любое чило)')
  // initData.auth.fsid = prompt('Введи fsid (любые символы)')
}
