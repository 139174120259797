import '@modules/auth/auth'
import '@modules/api/api'
import '@modules/api/predict'
import '@modules/api/winners'
import '@modules/scroll'
import '@modules/popup'
import '@modules/winners-popup'
import '@modules/metrics/link-queries'

// todo: не нужен - нет футера
// import './modules/metrics/gtm-events'
// todo: не нужен - новый модуль авторизации
// import './modules/reload-page-event'
