import { formDisabledBtns } from '@modules/form-handlers'
import { setIsShowTimer } from '@modules/timer'

import { host, initData, textBtn } from '../init-state'
import { openPopup } from '../popup'

const form = document.querySelector('.form')
const formList = form.querySelector('.form__list')
const submitBtn = document.querySelector('.form__submit-btn')
const resultBtn = document.querySelector('.aside__result-btn')
const asideInfo = document.querySelector('.aside__info')

const popupUnauthorized = document.querySelector('#unauthorized')
const popupAccepted = document.querySelector('#accepted')
const popupError = document.querySelector('#accepted-error')

const getPredictFormData = () => {
  const predictData = {
    blocks: [],
    matchId: initData.matchId,
    clientId: initData.auth.cid,
  }

  const matches = formList.querySelectorAll('.form__match')

  matches.forEach((match) => {
    const commands = match.querySelectorAll('.form__command')
    const matchVote = {}
    matchVote.block_id = Number(match.dataset.id)
    commands.forEach((item) => {
      if (item.dataset.select === 'true') {
        matchVote.command_id = Number(item.dataset.id)
      }
    })

    predictData.blocks.push(matchVote)
  })

  const result = JSON.stringify({
    blocks: predictData.blocks,
    match_id: predictData.matchId,
    client_id: predictData.clientId,
  })

  return result
}

export const initPredictEvent = () => {
  const PredictSubmitHandler = (evt) => {
    evt?.preventDefault()

    if (!initData.submitForecast) {
      initData.submitForecast = true
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      headers.append('Accept', 'application/json')
      headers.append('customerid', initData.auth.cid)
      headers.append('fsid', initData.auth.fsid)

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: getPredictFormData(),
        redirect: 'follow',
      }

      fetch(`${host}/forecasts/create`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            formDisabledBtns()
            openPopup(popupAccepted)
            // TODO: написать функцию для этого блока
            form.classList.remove('form--vote-started')
            submitBtn.style.display = 'none'
            resultBtn.textContent = textBtn.notVote
            resultBtn.style.display = 'block'
            asideInfo.classList.add('aside__info--small')
            setIsShowTimer(true)
          } else openPopup(popupUnauthorized)
        })
        .catch((error) => {
          openPopup(popupError)
          console.log('error', error)
        })
    }
  }

  // form.addEventListener('submit', PredictSubmitHandler)
  submitBtn.addEventListener('click', PredictSubmitHandler)
}
