import { initData } from './init-state'

const mainLink = document.querySelectorAll('[data-btn]')
const logo = document.querySelector('.header__logo')

const isWebview = new RegExp('webview', 'gi').test(window.location.search)
export const webviewCheck = () => {
  if (initData.webview || isWebview) {
    logo.style.pointerEvents = 'none'
    mainLink.forEach((link) => {
      if (link.dataset.btn === 'main') link.style.display = 'none'
    })
  }
}
