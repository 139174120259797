import Cookies from 'js-cookie'

export enum CookiesPari {
  CID = 'headerApi.cid',
  FSID = 'headerApi.FSID',
}

export function getAuthCookies() {
  return {
    customerid: Number(Cookies.get(CookiesPari.CID))!,
    fsid: Cookies.get(CookiesPari.FSID)!,
  }
}

export function clearCookies() {
  deleteCookie(CookiesPari.CID)
  deleteCookie(CookiesPari.FSID)
  Cookies.remove(CookiesPari.CID)
  Cookies.remove(CookiesPari.FSID)
}

export function checkAuthCookies() {
  return [CookiesPari.CID, CookiesPari.FSID].every((name) => Cookies.get(name))
}

// Для удаление кук установленных сервером pari
function deleteCookie(name: string) {
  const options = {
    path: '/',
    domain: '.pari.ru',
  }

  let updatedCookie =
    encodeURIComponent(name) + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC'

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey

    const optionValue = options[optionKey as keyof typeof options]

    updatedCookie += '=' + optionValue
  }

  document.cookie = updatedCookie
}
